<style lang="scss">
@import "~@/assets/css/var";

.over-province {
    position: absolute;
    left: 50%;
    top: 40px;
    padding-left: 60px;

    .el-tag {
        padding: 0 20px;
        margin-right: 10px;
        margin-bottom: 5px;
        cursor: pointer;

        &:hover {
            color: $main;
        }
    }

    &-p {
        padding-top: 20px;
        font-size: 14px;
        line-height: 1;
        font-weight: 500;
        color: #000000;

        span {
            padding-right: 30px;
        }
    }
}

.overview-province {
    .relative {
        display: flex;
        height: 100%;

        .make-table {
            width: 55%;

            .my-table {
                height: 100%;

                .icon {
                    cursor: pointer;
                    text-align: center;
                    width: 100%;

                    .iconfont {
                        font-size: 24px;
                    }
                }

                .active {
                    color: $main;
                }

                .up {
                    color: $red;
                }

                .down {
                    color: $green;
                }
            }
        }

        .echart {
            width: 45%;
            padding: 20px 20px 20px 0;

            .echart-china {
                height: 38vh;

                .canvas {
                    height: 38vh;
                    margin: 0 auto;
                    width: 100%;
                }
            }

            .echart-trend {
                height: 30vh;

                .canvas {
                    height: 30vh;
                    margin: 0 auto;
                    width: 100%;
                }
            }
        }

        .no-data {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
        }
    }
}
</style>

<template>
    <div class="corp-sale flex-item overview-province" v-loading="loading">
        <!-- <p class="corp-sale-title">
            <span @click="exportInfo()"><i class="el-icon-document"></i>导出数据</span>
        </p> -->
        <div class="relative" :style="{ 'align-items': error === 'A0004' ? 'center' : 'unset' }">
            <template v-if="error === 'A0004'">
                <ts-error style="width: 100%;"></ts-error>
            </template>
            <template v-else>
                <div class="make-table">
                    <ts-table hide-table-head :cols="cols" hide-border :data="list" :page-param="pageParam"
                        :loading="loading" hide-detail :height="null" @handleSizeChange="handleSizeChange"
                        @handleSort="sort" stop-empty-hide @handleCurrentChange="handleCurrentChange" full
                        zero-to-empty>
                        <template #searchLeft>
                            <ts-search-left v-if="isSearchLeft" :searchCols="searchCols" :cols="cols"
                                table-name="sale_year" @searchClose="searchClose"
                                @handleFilter="onSearch"></ts-search-left>
                        </template>
                        <template v-slot:slot_price="{ props }">
                            <div @click="showCanvas(props)" class="icon" :class="{ 'active': index === props.$index }">
                                <i class="iconfont icon-a-rongqi1"></i>
                            </div>
                        </template>
                        <template v-slot:slot_rate="{ props }">
                            <div :class="[props.row.rate > 0 ? 'up' : 'down']">
                                {{ props.row.rate | filterRate }}
                            </div>
                        </template>
                        <template v-slot:slot_upOrDown="{ props }">
                            <div :class="[props.row.rate > 0 ? 'up' : props.row.rate < 0 ? 'down' : '']">
                                {{ props.row.upOrDown }}
                            </div>
                        </template>
                    </ts-table>
                </div>
                <div class="echart" v-if="list.length !== 0" v-loading="echartLoading">
                    <div class="echart-china">
                        <div :id="nameKey" class="canvas" style="flex:1; height: 100%;"></div>
                    </div>
                    <div class="echart-trend">
                        <div :id="trendId" class="canvas" style="flex:1; height: 100%;"></div>
                    </div>
                </div>
                <div v-else class="no-data">
                    <ts-empty></ts-empty>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import myMixin from '@/util/mixin'
import help from '@/util/help'
export default {
    mixins: [myMixin],
    props: {
        visitId: {
            type: Number,
            default: 0
        },
        cols: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            nameKey: 'overviewProvince',
            trendId: 'overview-province-trend',
            error: '',
            loading: false,
            index: 0,
            total: 0,
            stop: true,
            allYears: this.$variable.years.map((item) => {
                return {
                    key: item.key,
                    value: item.value,
                    checked: true,
                }
            }),
            pageParam: {
                pageNo: 1,
                pageSize: 20,
                total: 0,
            },

            list: [],
            computedList: [],
            conditionList: [],

            echartLoading: false,

            isSearchLeft: false,
            searchCols: [],
        }
    },
    filters: {
        filterRate(val) {
            let str = '';
            if (val) str = help.accMul(val, 100) + '%';
            return str;
        }
    },
    mounted() {
        this.initCustom()
        // this.initEchart( {
        //     comCode: "YP1909006581-1"
        // })
    },
    methods: {
        //关闭左侧筛选框
        searchClose() {
            this.isSearchLeft = false
        },
        initCustom() {
            this.pageParam.pageNo = 1
            this.getCustomList()
        },

        getCustomList() {
            this.loading = true
            this.$api.post(`overview/saleYear/sumAmountByCom`, {
                conditionList: this.conditionList,
                page: this.pageParam,
                // sort: this.sortParam ? this.sortParam : this.initSort
            }).then(res => {
                if (res.code === 'A0004') {
                    this.error = res.code
                }
                if (res.success) {
                    this.pageParam.total = res.data.totalCount
                    this.list = res.data.records
                    if (this.list && this.list.length !== 0) {
                        this.initEchart(res.data.records[0])
                    }
                }
            }).finally(() => {
                this.loading = false
            })
        },

        handleSizeChange(val) {
            this.pageParam.pageSize = val
            this.handleCurrentChange(1)
        },

        handleCurrentChange(page) {
            this.pageParam.pageNo = page
            this.getCustomList()
        },

        onSearch(data) {
            let conditionList = [];
            if (data && data.length !== 0) {
                data.forEach(item => {
                    if (item.model) {
                        switch (item.field.listQueryModel) {
                            case this.$variable.searchType.LIKE:
                                conditionList.push({
                                    name: item.field.fieldName,
                                    op: 'like',
                                    value: item.model,
                                })
                                break;
                            case this.$variable.searchType.SELECT:
                                if (item.model && item.model.length != 0) {
                                    conditionList.push({
                                        name: item.field.fieldName,
                                        op: 'in',
                                        value: item.model,
                                    })
                                }
                                break;
                            default:
                                if (item.model && item.model.length === 2) {
                                    conditionList.push({
                                        name: item.field.fieldName,
                                        op: 'ge',
                                        value: item.model[0],
                                    })
                                    conditionList.push({
                                        name: item.field.fieldName,
                                        op: 'le',
                                        value: item.model[1]
                                    })
                                }
                        }
                    }
                })
            }
            this.searchCols = data
            this.conditionList = conditionList
            this.initCustom()
        },

        sort(param) {
            this.sortParam = {}
            if (param) {
                this.sortParam = param
            }
            this.initCustom()
        },

        getCheckedYear() {
            let arr = []
            this.allYears.forEach(item => {
                if (item.checked) {
                    arr.push(item)
                }
            })
            return arr
        },

        showCanvas(props) {
            this.index = props.$index
            this.initEchart(props.row)
        },

        //中国地图 + 单个药品趋势图
        initEchart(row) {
            this.echartLoading = true
            let p1 = new Promise((resolve, reject) => {
                //中国地图
                this.$api.get('overview/saleYear/lastYearAmountByProvince', { comCode: row.comCode, compCode: row.compCode }).then(res => {
                    this.packageData(res.data)
                }).catch(() => {
                    reject()
                    this.echartLoading = false
                }).finally(resolve)
            })

            let p2 = new Promise((resolve, reject) => {
                //单个药品趋势图
                this.$api.get('overview/saleYear/saleAmountByComCode', { comCode: row.comCode, compCode: row.compCode }).then(res => {
                    let list = res.data.map(item => {
                        item.rate = this.$help.parseFloat100(item.rate)
                        return item
                    })
                    let option = {
                        title: {
                            text: row.comName,
                            x: 'left', y: 'top',
                            textAlign: 'left',
                            textStyle: {
                                fontSize: 14
                            }
                        },
                    }
                    this.$help.renderBar(this.trendId, list, 'year', option)
                }).catch(() => {
                    reject()
                    this.echartLoading = false
                }).finally(resolve)
            })
            Promise.all([p1, p2]).then(() => {
                this.echartLoading = false
            })
        },

        packageData(list) {
            this.total = 0
            this.computedList = list.map(item => {
                return {
                    name: item.provinceName,
                    value: parseFloat(item.yearAmount.toFixed(2)),
                }
            })
            this.renderChinaCustom(this.nameKey, this.computedList)
        },

        checkOne(item) {
            item.checked = !item.checked
            if (this.getCheckedYear().length === 0) {
                item.checked = true
                this.$store.commit('error', '请至少选择一年')
            }
        },

        renderChinaCustom(el, data) {
            const yData = [];
            data.sort(function (o1, o2) {
                if (isNaN(o1.value) || o1.value == null) return -1;
                if (isNaN(o2.value) || o2.value == null) return 1;
                return o1.value - o2.value;
            });
            let max = 100
            for (let i = 0; i < data.length; i++) {
                yData.push(data[i].name);

                // 获取最大值
                max = Math.max(max, parseFloat(data[i].value))
            }
            let option = Object.assign({
                tooltip: {
                    show: true,
                    formatter: function (params) {
                        return params.name + '：' + (params.data ? params.data['value'] : 0)
                    },
                    backgroundColor: '#fff',
                    textStyle: {
                        color: '#333' //设置文字颜色
                    },
                    extraCssText: 'box-shadow: 0px 0px 36px 0px rgba(100, 100, 100, 0.43);',
                },
                visualMap: {
                    type: 'continuous',
                    text: ['', ''],
                    showLabel: true,
                    seriesIndex: [0],
                    orient: 'horizontal',
                    itemHeight: 300,
                    min: 0,
                    max: max,
                    inRange: {
                        color: ['#d2d8ec', '#3759CD']
                    },
                    textStyle: {
                        color: '#000'
                    },
                    top: 0,
                    left: 'left',
                },
                geo: [
                    {
                        map: 'china',
                        zlevel: 5,
                        label: {
                            normal: {
                                show: false
                            },
                            emphasis: {
                                show: true
                            }
                        },
                        itemStyle: {
                            normal: {
                                areaColor: "#d2d8ec",
                                borderColor: "#fff",
                                borderWidth: 1,
                            },
                        },
                        emphasis: {
                            itemStyle: {
                                areaColor: "#409EFF4d", //鼠标经过颜色
                            },
                        }
                    },
                    {
                        map: 'china',
                        top: '11%',
                        zlevel: 4,
                        label: {
                            normal: {
                                show: false
                            },
                            emphasis: {
                                show: true
                            }
                        },
                        itemStyle: {
                            // color: '#fff', // 背景
                            borderWidth: '1', // 边框宽度
                            borderColor: '#3C5FA1', // 边框颜色
                        }
                    }],
                series: [
                    {
                        name: '销量',
                        type: 'map',
                        roam: false,
                        geoIndex: 0,
                        label: {
                            show: false,
                        },
                        data: data
                    },
                ]
            });
            const myChart = window.echarts.init(document.getElementById(el))
            myChart.clear()
            myChart.setOption(option)
            window.addEventListener('resize', function () {
                myChart.resize()
            })
        },

        renderBarCustom(el, data, comName) {
            let option = {};
            if (data.length !== 0) {
                let xAxisData = [], seriesData = [];
                data.forEach(item => {
                    xAxisData.push(item.year)
                    seriesData.push(item.amount)
                })
                option = {
                    title: {
                        text: comName,
                        x: 'center',
                        y: 'top',
                        textAlign: 'left',
                        textStyle: {
                            fontSize: 14
                        }
                    },
                    xAxis: {
                        data: xAxisData
                    },
                    yAxis: {
                        type: 'value'
                    },
                    grid: {
                        bottom: 30
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    series: [
                        { data: seriesData, type: 'bar' }
                    ]
                };
            } else {
                option = {
                    title: {
                        show: true,
                        text: '暂无数据',
                        left: 'center',
                        top: 'center'
                    },
                }
            }
            const myChart = window.echarts.init(document.getElementById(el))
            myChart.clear()
            myChart.setOption(option)
            window.addEventListener('resize', function () {
                myChart.resize()
            })
        },

        //数据导出
        exportInfo(txt = '确定导出吗') {
            if (this.error === 'A0004') return false
            this.$help.exportInfo(txt, "overview/saleYear/exportSumAmountByCom", {
                conditionList: this.conditionList,
                params: {}
            })

        },
    }
}
</script>